<template>
<!-- start -->
<vue-basic-alert :duration="4000" ref="alert" />
<div class="register-container">
        <div class="register-form-container">
            <form id="userForm" novalidate autocomplete="off">
                <div class="heading">
                    <h3>Receipt</h3>
                </div>
                <div class="container">    
                    <label for="cNic">NIC Number/Reference Number: 
                    </label>
                   <span> <input type="text" name="cNic" placeholder="Customer NIC" id="cNic" class="form-control"
                    @input ="showCustomer" v-model="cusObj.nic"/>
                        <p class="error-mess" v-if="errObj.nicErr.length > 0">{{ errObj.nicErr[0] }}</p>
                    </span> 
                </div>
                <br>    
            </form>
        </div>

<!-- end -->
</div>
<!-- end -->
   <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="invoice-container" >
        <div class="invoice-form-container" >
           
            <form id="invoiceForm" novalidate autocomplete="off">
                <div id="PrintContent">
                    <p style="text-align:right; font-size:smaller;" >එල්.ජී.1</p>
                    <div class="form-group">
                       <p style="text-align: center; font-size: large;">Invoice | ලදුපත
                        <br>
                            {{sabhadetail.sb_name_en}} |  {{sabhadetail.sb_name_sin}}
                        <!-- {{sabhadetail.sb_address}}, Tel:{{sabhadetail.sb_contact}}&nbsp; Fax:{{sabhadetail.fax}}<br>
                        VAT Number:{{ sabhadetail.vat_num }} -->
                        </p>
                   <table style="border: none; width: 100%;" >
                    <tr>
                        <td style="width: 50%; border: none;"></td>
                    <td style="border: none;">
                        <p for="iHead" style="text-align:left; font-size:small"><b>Receipt Number:</b> {{invoiceNum}}
                        <br>
                        <b>Date:</b> {{formattedDate(new Date())}}
                        </p> 
                    </td>
                </tr>
                <!-- <tr>
                    <td style="width: 50%; border: none"></td>
                   <td style="border: none;">
                        <h5 for="iHead" style="text-align:left"><b>Date:</b> {{formattedDate(new Date())}}
                        </h5> 
                    </td>
                </tr> -->
                   </table>
                   <p><b>Customer name:</b>
                        
                        {{cusObj.name}}<br>
                        <b>Customer Address: </b>
                        
                        {{cusObj.address }}</p>
    <div>(මුදල්- C චෙක්-Q ඝෘජු-D හරස්-X)</div>
                <!-- <table style="text-align: center; width: 100%; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
                <thead>
                    <tr >   
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income Head</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 30%;">Description</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Amount</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">VAT</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Stamp</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Discount</th>
                        <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total</th>
                        

                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" r in invoicedetails" :key="r.id">
                        <td style="text-align: Center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.sb_rate_head }}</td>
                        <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 40%;" >{{ r.description }}</td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber(r.amount) }}</td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{$formatNumber(calVat(r.amount,r.vat))}}</td>
                       // <td>{{ r.vat }}</td> 
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber(r.stamp) }}</td>
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber(r.discount) }}</td>
                        
                        <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ $formatNumber(singleRowTotal(calVat(r.amount,r.vat),r.amount,r.stamp,r.discount)) }}</td>
                       // <td>{{ r.sub_nic }}</td> 
                      
                    </tr>
                    <tr>
                        <th colspan="2" style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total:</th>
                        // <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[0])}}</th> 
                        <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[4])}}</th>
                        <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[3])}}</th>
                        <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[2])}}</th>
                        <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[1])}}</th>
                        <th style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[0])}}</th>
                    </tr>
                </tbody>
            </table> -->
          <!-- <div style="align-content: left;"><b>Total: {{calculateSummaryPrice()[0]}} </b> </div>  -->
           <!-- new invoice table structure -->
           <table style="width: 100%;  border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >
                    <thead>
                        <tr >   
                            
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Description</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; ">Payment Type</th>
                            <!-- <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">VAT</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Discount</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Stamp</th> -->
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Amount</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income Head</th>
                         
                            
    
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for=" r in invoicedetails" :key="r.id">
                          
                            <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 50%;" >{{ r.description }}</td>
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; text-align: center;width: 10%;" >{{this.radioCheck}}</td>
                            <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{calVat(r.amount,r.vat)}}</td> -->
                            <!-- <td>{{ r.vat }}</td> -->
                            <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.discount }}</td> -->
                            <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.stamp }}</td> -->
                            <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{$formatNumber(r.amount) }}</td>
                            <td style="text-align: Center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;" >{{ r.sb_rate_head }}</td>
                            <!-- <td>{{ r.sub_nic }}</td> -->
                          
                        </tr>
                        <tr>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total:</th>
                           
                            <th colspan="2" style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{$formatNumber(calculateSummaryPrice()[0])}}</th>
                        </tr>
                    </tbody>
                </table>
                <p>Amount Received: {{calculateSummaryPrice()[5]}} Only </p>
                <p>Cheque/Money Order Number :{{checquenum}} </p>
            <!-- new invoice end -->
            </div>
        </div>
            <div class="form-group" id="accdiv" style="display: none;" @click="checkAccExist">
                    <div class="form-group">
                        <label for="iAcc"><b>Account Number:</b> 
                        </label>
                       
                           <div><select v-model="invObj.accNo" id="iHead" class="form-control" >
                                <option value="" selected disabled>
                                     Choose
                                 </option>
                                <option v-for="a in accNumbers" :key="a.id" id="baid"  
                                v-bind:value =a.acc_no>
                                   <div>{{a.acc_no }}</div> 
                                </option>
                            </select>
                         </div> 
                        <p class="error-mess" v-if="errObj.acNumErr.length > 0">{{ errObj.acNumErr[0] }}</p>
                    </div><br>
            </div>
            <!-- rest -->
            <div class="form-group">
                        <label for="iMethod"><b>Payment Method:</b> &nbsp;&nbsp;&nbsp;
                        </label>
                       <input type="radio" id="cheque" name="cheque" value="cheque" v-model="radioCheck" @change="showChqDetail()" />
                        <label for="yes"> &nbsp; Cheque &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" id="cash" name="cash" value="cash" v-model="radioCheck" @change="hideChqDetail()" />
                        <label for="yes"> &nbsp; Cash &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" id="direct" name="direct" value="direct" v-model="radioCheck" @change="hideChqDetail()" />
                        <label for="yes"> &nbsp; Direct &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" id="pos" name="pos" value="pos" v-model="radioCheck" @change="hideChqDetail()" />
                        <label for="yes"> &nbsp; POS Machine &nbsp;&nbsp;&nbsp;</label>
                        <input type="radio" id="qr" name="qr" value="qr" v-model="radioCheck" @change="hideChqDetail()" />
                        <label for="yes"> &nbsp; QR &nbsp;&nbsp;&nbsp;</label>
                       <p class="error-mess" v-if="errObj.radioErr.length > 0">{{ errObj.radioErr[0] }} </p> 
                </div>
                <div class="form-group2" id="cheqdetail" style="display: none;">
                        <label for="icheqno"><b>Cheque Number:</b> &nbsp;&nbsp;&nbsp;
                        </label>
                        <div class="form-group">
                        <input type="text" id="iChno" name="iChno" class="form-control" @change="showCheqnumber()"
                            v-model="invObj.chNo" />
                    </div>
                    <p class="error-mess" v-if="errObj.chqnum.length > 0">{{ errObj.chqnum[0] }}</p>
                            <!-- <label for="ivalid"><b>Valid till:</b>
                        </label>
                        <input type="text" id="iChval" name="iChval" class="form-control" 
                            v-model="invObj.chVal" /><br> -->
                    </div>
            
    <div id="PrintContent2">
                
            <table class="nobordertbl">
        <tr>
            <td style="text-align:left;width: 50%;">
               <!-- Prepaired By: -->

            </td>
            <td style="text-align:left;width: 50%;">
                Cashier: {{ userName }} 
            </td>
        </tr>
        <tr>
            <td style="text-align:left;width: 50%;">
                Date-Time: {{ new Date().toLocaleString() }}

            </td>
            <td style="text-align:left;width: 50%;">
                Cashier Signature: .....................................
            </td>
        </tr>
    </table>
    </div>
    
            </form>
        </div>
        <br>
        <div class="btnrow">
                <div class="">
                    <!-- <input type="button" value="Cancel" class=" btn" @click="clearform" >&nbsp;&nbsp; -->
                    <input type="button" id="PrintButton" :disabled="processing" style="width: 10rem; display: none;" value="Submit" class=" btn" @click="insertInvoice">
                </div>
            </div>
    </div>
</template>
<script>
import axios from 'axios';
import VueBasicAlert from 'vue-basic-alert';
// import VuePrintNB from 'vue-print-nb';
export default {
    name: "Invoice",

   data (){

    return{
            nic:"",
            sabha: "",
            userLevel:"",
            userName:"",
            sabhadetail:[],
            invoicedetails:[],
            cashier:[],
            total:0,
            inObj: {invId: ""},
            getinvoice:[],
            invoiceNum:"",
            invObj:{chNo:"0", chVal:"", accNo:"",},
            theUser:[],
            cusObj : { nic: "", name: "", phone:"", address:"" },
            errObj : {nicErr:[], nameErr:[], phoneErr:[], addErr:[], acNumErr:[],chqnum:[],radioErr:[]},
            employeedetail:[],
            accNumbers:[],
            type:'',
            cashTotal:0,
            chequeTotal:0,
           shopDid:'',
           shopDetails:[],
           arrears:0,
           monthlypay:0,
           date : new Date(),
          
           getautoinvNum:[],
           matchUser:undefined,
           preparedby:'',
           Nic12:'',
          radioCheck:'',
        //   serverDate:null,
           currentDate:new Date(),
        //    res:[]
        retrnnum:'',
        checquenum:0,
    }
   },

   created(){
    this.getDataFromSessionStorage()
    this.showCustomer()
    this.getAllBankAccs()
    // this.getServerDate()
   },
//    computed : {
//             // isGreaterThan10th() {
//             //     return this.currentDate.getDate() > 10;
//             //     },
            
//         },

   methods:{
    //      async getServerDate() {
    //   try {
    //     this.res = (await axios.get('/getserverdate')).data; // Assuming this endpoint returns the server date
    //     this.serverDate = this.res.serdate; // Adjust this according to your server's response
    //   } catch (error) {
    //     console.error('Error fetching server date:', error);
    //   }
    // },
    formattedDate() {
           
            let date = new Date()
            const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
    const fdate=`${year}-${month}-${day}`;
    const options = { timeZone: 'Asia/Colombo', year: 'numeric', month: '2-digit', day: '2-digit' };
    return fdate.toString('en-US', options);
    //   return `${year}-${month}-${day}`;
          },
//     formattedDate() {
//     const options = {
//       timeZone: 'Asia/Colombo', // SLST time zone
//       year: 'numeric',
//       month: '2-digit',
//       day: '2-digit'
//     };
//     // Get UTC formatted date
//     const utcDate = new Date(Date.UTC(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()));
//     // Format date in SLST
//     const slstDate = utcDate.toLocaleDateString('en-US', options);
//     return slstDate;
//   },
    async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                            this.userName = data.userName
                        }
             this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
            
// this.currentDate=this.serverDate
         
        },
        async getMatchUser(nic) {
            // await axios.get('/employee/' + nic);
            let data = await axios.get('/employee/' + nic);
            this.matchUser = data.data;
            // return this.matchUser.emp_name
        },
// getNameOf :function(nic){
//     this.getMatchUser(nic)
//     let nameSub = this.matchUser.emp_name

//     return nameSub

// },
        addZero(num) {

num = num.toString();
    return '19' + num.slice(0, 5) + '0' + num.slice(5, -1);
},
addZeroPadding: function(cusnic) {
// this.len= this.loginObj.nic.charAt(9).toUpperCase()
// Check if the user input is a valid number
// if (!isNaN(parseInt(this.cusObj.nic)) && this.cusObj.nic !== null && this.cusObj.nic.charAt(9).toUpperCase() === 'V') {
if (!isNaN(parseInt(cusnic)) && cusnic !== null && (cusnic.charAt(9).toUpperCase() === 'V' || cusnic.charAt(9).toUpperCase() === 'X')) {
    
    this.paddedNum = this.addZero(cusnic);

}else{
    this.paddedNum=parseInt(cusnic);
}
return this.paddedNum
},
        
        async showCustomer() {
            let letter_first= (this.cusObj.nic).charAt(0)
            if(letter_first=='R'){
                this.Nic12=this.cusObj.nic
            }else{
            // this.cus_nic = JSON.parse(sessionStorage.getItem("cus_nic"))
            this.Nic12 = this.addZeroPadding(this.cusObj.nic); 
            }
            this.invoicedetails = (await axios.get('/receiptrownw/' + this.Nic12+"/"+this.sabha)).data
            this.theUser= (await axios.get('/customers/' +this.Nic12+"/"+ this.sabha)).data;
               if(this.invoicedetails && this.invoicedetails[0]){
                this.cusObj.name = this.invoicedetails[0].cus_name;
                this.cusObj.phone = this.invoicedetails[0].cus_contact;
                this.cusObj.address = this.invoicedetails[0].cus_address;

                // this.invoicedetails = (await axios.get('/receiptrow/' + this.Nic12)).data
                this.getMatchUser(this.invoicedetails[0].sub_nic)
                // this.preparedby = this.matchUser
               }
                document.getElementById('PrintButton').style.display ='block';
                if (this.invoicedetails.length>0) {
                 document.getElementById('accdiv').style.display = 'block';
                
            }
                
              
},
    // checkUser(){
    //     if (this.invoicedetails.length>0) {
    //              document.getElementById('accdiv').style.display = 'block';
    //     }else{
    //         document.getElementById('accdiv').style.display = 'none';
    //     }
    // }, 
    checkAccExist(){
        if (this.invoicedetails.length>0) {
                 document.getElementById('accdiv').style.display = 'block';
        }else{
            document.getElementById('accdiv').style.display = 'none';
            document.getElementById('radiodiv').style.display='none'
        }
        //Radio button
        if(this.invObj.accNo!==""){
            document.getElementById('radiodiv').style.display='block'
        }else{
            document.getElementById('radiodiv').style.display='none'
        }
    },
   async getAllBankAccs() {
             this.accNumbers = (await axios.get('/sabhaaccounts/' + this.sabha)).data;
          
        },
        calculateSummaryPrice: function () {
            let subtotal = 0;
            let discount = 0;
            let stampfee =0;
            let vat =0;
            let i = 0;
            let subamount=0;
            // let disc =0;
            // let stamp=0;
            // let vattemp =0;
            let vatamount =0;
            let amount=0;
            while (i < this.invoicedetails.length) {
                // subtotal = subtotal +(parseInt(this.invoicedetails[i].amount)- (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100) +parseInt(this.invoicedetails[i].stamp))
                // subtotal = subtotal +(parseInt(this.invoicedetails[i].amount)- (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100) +(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].stamp) )/100))+(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].vat) )/100)
                discount = discount + (parseFloat(this.invoicedetails[i].discount))
                stampfee = stampfee + (parseFloat(this.invoicedetails[i].stamp))
                amount = parseFloat(this.invoicedetails[i].amount)
                vatamount=amount* parseFloat((this.invoicedetails[i].vat) /100)
                vat = vat+ vatamount
                subtotal = subtotal + (parseFloat(this.invoicedetails[i].amount))- (parseFloat(this.invoicedetails[i].discount)) + (parseFloat(this.invoicedetails[i].stamp)) +(parseFloat(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].vat) )/100);
                // subtotal=subtotal+stampfee+vat+parseFloat(this.invoicedetails[i].amount)-discount
                // discount =(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100)
                // stamp = (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].stamp) )/100)
                subamount =subamount+parseFloat(this.invoicedetails[i].amount)
                i = i + 1
                
            }
            
            let total = subtotal ;
            let fixedtotwo=total
            this.numberToText(fixedtotwo) 
           
            return [total.toFixed(2), discount.toFixed(2),stampfee.toFixed(2),vat.toFixed(2),subamount.toFixed(2),this.retrnnum];
        },
       calVat:function name(amo,vt) {
        let vatamount= parseFloat(amo)*parseFloat(vt)/100

        return vatamount.toFixed(2)
       },
                    //start
    numberToText (convnumber) {
        // convnumber=this.calculateSummaryPrice()[0]
  // Basic implementation, you can expand this to cover more cases
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
      const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
      const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
      const suffixes = ['', 'thousand','million', 'billion', 'trillion']; // Adjust as needed

      const [wholePart, decimalPart] = convnumber.toString().split('.').map(Number);

      let wholePartText = this.convertWholeNumberToText(wholePart, ones, teens, tens,suffixes);
      let decimalPartText = this.convertWholeNumberToText(decimalPart, ones, teens, tens,suffixes);

      let result = wholePartText +' Rupees ';
      if (decimalPartText !== '') {
        if(decimalPart.toString().length>1){
            console.log(decimalPart.length)
        result += ' and ' + decimalPartText + " Cents ";
        }else{
            let decimalpartone = decimalPart*10
            let decimalPartTextcents = this.convertWholeNumberToText(decimalpartone, ones, teens, tens,suffixes);
            result += ' and ' + decimalPartTextcents + " Cents ";
        }
      }

    //   return result.trim();
    this.retrnnum= result.trim();
    },
    convertWholeNumberToText(number, ones, teens, tens, suffixes) {
    if (number === 0) {
        return 'zero';
    }

    let result = '';
    let suffixIndex = 0;

    while (number > 0) {
        if (number % 1000 !== 0) {
            result = this.convertThreeDigitNumberToText(number % 1000, ones, teens, tens) + ' ' + suffixes[suffixIndex] + ' ' + result;
        }
        number = Math.floor(number / 1000);
        suffixIndex++;
    }

    return result.trim();
},
    convertThreeDigitNumberToText(number, ones, teens, tens) {
      if (number === 0) {
        return 'zero';
      }

      let result = '';

         if (number >= 100) {
        result += ones[Math.floor(number / 100)] + ' hundred ';
        number %= 100;
      }

      if (number >= 20) {
        result += tens[Math.floor(number / 10)] + ' ';
        number %= 10;
      }

      if (number >= 10) {
        result += teens[number - 10] + ' ';
        return result.trim();
      }

      if (number > 0) {
        result += ones[number] + ' ';
      }

  return result.trim();
 
},
showCheqnumber(){
                    this.checquenum = this.invObj.chNo
    } ,
       singleRowTotal:function(vat,amount,stam,disc){
        let total = (parseFloat(amount)+ parseFloat(vat)+ parseFloat(stam))-parseFloat(disc);
        return total.toFixed(2)
       },
         
        showChqDetail:function(){
            document.getElementById('cheqdetail').style.display ='block';
        },
        hideChqDetail:function(){
            document.getElementById('cheqdetail').style.display ='none';
        },
        async clearform(){
            // this.Nic12 = this.addZeroPadding(this.cusObj.nic); 
            
           document.getElementById("invoiceForm").reset()
           document.getElementById("userForm").reset()
        //    await axios.delete("/tempinv/" + this.Nic12)
           this.invoicedetails = ""
            this.theUser= ""
            this.cusObj.name = "";
            this.cusObj.phone = "";
            this.cusObj.address = "";
            this.invObj.chNo="",
            this.invObj.accNo="";
            this.cusObj.nic="";
            document.getElementById('PrintButton').style.display ='none';
           this.$router.push("/Invoice");
        },
        // handle submit
        resetCheckErr: function () {
           this.errObj.acNumErr = [];
           this.errObj.radioCheck =[];
        //    this.errObj.chqnum =[];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           
           if (!this.invObj.accNo) {
               this.errObj.acNumErr.push("Account Number is required");
           } 
           if (!this.radioCheck){
            this.errObj.radioErr.push("Payment Method is required");
           }
           else {
            this.errObj.radioErr = '';
        }
         
           //chqnum  
        //    if (!this.invObj.chNo) {
        //        this.errObj.chqnum.push("Cheque Number is required");
        //    }     
       },
       getPaymentOfMonth:function( paymonth1,rent) {
            
            let Mpayment =rent;

                  if (paymonth1== this.currentDate.getMonth()) {
                  Mpayment=0
                  }

                  return Mpayment;
         },
        
         async insertInvoice(e) {
  try {
    if (this.invoicedetails.length > 0) {
      this.checkForm();
      if (!this.checkEmptyErr()) {
        e.preventDefault();
      } else {
        // Set processing flag to true to disable the button
        this.processing = true;

        // Generate new invoice number
        this.getinvoice = (await axios.get('/sabhagenaratenum/' + this.sabha)).data;
        let lastGennum = parseInt(this.getinvoice[0].gen_num);
        this.invoiceNum = `(${this.sabha})${lastGennum}`;

        // Calculate totals based on payment type
        if (this.radioCheck === 'cash') {
          this.cashTotal = this.calculateSummaryPrice()[0];
          this.chequeTotal = 0;
        } else {
          this.chequeTotal = this.calculateSummaryPrice()[0];
          this.cashTotal = 0;
        }

        // Prepare data to save invoice
        let data = {
          sabha: this.sabha,
          invoice_num: this.invoiceNum,
          cheque_no: this.invObj.chNo,
          acc_number: this.invObj.accNo,
          cus_nic: this.invoicedetails[0].cus_nic,
          cus_address: this.invoicedetails[0].cus_address,
          cus_contact: this.invoicedetails[0].cus_contact,
          description: this.invoicedetails[0].description,
          total_amount: this.calculateSummaryPrice()[0],
          cheque_total: this.chequeTotal,
          cash_total: this.cashTotal,
          total_stamp: this.calculateSummaryPrice()[2],
          total_discount: this.calculateSummaryPrice()[1],
          total_vat: this.calculateSummaryPrice()[3],
          cashier_nic: this.nic,
          date: this.formattedDate(),
          type: this.radioCheck,
        };

        // Save the invoice
        await axios.post("/saveinvoice/", data);

        // Save details of invoiced items
        for (let i of this.invoicedetails) {
          let tempsaveData = {
            invoice_num: this.invoiceNum,
            sabha: this.sabha,
            cus_nic: i.cus_nic,
            cus_name: i.cus_name,
            cus_contact: i.cus_contact,
            cus_address: i.cus_address,
            sb_rate_head: i.sb_rate_head,
            description: i.description,
            amount: i.amount,
            stamp: i.stamp,
            discount: i.discount,
            shoptotalarrears: i.shoptotalarrears,
            paymonth: i.paymonth,
            vat: i.vat,
            shopdid: i.shopdid,
            date: this.formattedDate(),
            sub_nic: i.sub_nic,
          };
          await axios.post("/addtosave/", tempsaveData);
          this.invObj.accNo = "";

          // Update shop arrears if applicable
          if (i.shopdid != 0) {
            let shopdata = {
              arrears: i.shoptotalarrears,
              pay_month: i.paymonth,
            };
            await axios.put("/shoparrears/" + i.shopdid, shopdata);
          }
        }

        // Update invoice number generator
        this.nextid = lastGennum + 1;
        const genInvNum = {
          gen_num: this.nextid,
        };
        await axios.put('/sabhagenaratenum/' + this.sabha, genInvNum);

        // Clear invoicedetails and navigate to print page
        this.invoicedetails = [];
        await axios.delete("/tempinv/" + this.Nic12);
        this.$router.push({
          name: 'InvoicePrint',
          params: {
            data: this.invoiceNum,
            pay_type: this.radioCheck,
            chq_num: this.invObj.chNo,
          }
        });
        this.cusObj.nic = "";
        this.invoicedetails = "";
        this.cusObj.name = "";
        this.cusObj.address = "";
        this.invoiceNum = "";
          this.checquenum=""
        document.getElementById('PrintButton').style.display = 'none';

        // Set processing flag back to false after everything is done
        this.processing = false;
      }
    } else {
      this.$refs.alert.showAlert('error', 'Please add NIC or Reference Number to Continue');
    }
  } catch (error) {
    console.error('Error during invoice insertion:', error);
    // Handle error if needed
  }
}},


components: {
        VueBasicAlert
    }
};
</script>
<style scoped>
.nobordertbl{
        border-collapse: collapse;
        border: none;
        font-size: 1.2rem;
        width:100%;
    }
    .nobordertbl td{
        border: none;
    }
.ttable {
     
     width: 100%;
     border-width:1px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 0.8rem;
    text-align: right;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
      border-collapse: collapse;
      
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
/* padding-right: 0.7rem; */
}
.invoice-container {
    background-color: #fff;
    /* height: 100vh; */
    padding: 2rem;
    font-size: 16px;
    align-content: center;
   /* min-height: 72.3vh;*/
}
.invoice-container .invoice-form-container {
    background: #fff;

}

.invoice-container .invoice-form-container .heading{
   padding: 0%;

}

.invoice-container .invoice-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    padding-left: 2rem;
}
.h4 {
    /* padding-bottom: 1rem; */
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}
.invoice-container .invoice-form-container form h3 {
    /* padding-bottom: 1rem; */
    font-size: 1.3rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}
.invoice-container .invoice-form-container form .table {
    /* margin: 1rem 0; */
    width: 95%;
    /* border-style: solid; */
    font-size: 1.2rem;
    /* background-color : #e9e4e9; */
    padding-left: 5rem;
     /* height: 3rem; */
     border-color : #130f40;
     /* border-width:2px; */
     border-top: 0.5px solid;
     border-left: 0.5px solid;
     border-right: 0.5px solid;
     border-bottom: 0.5px solid;
}
.invoice-container .invoice-form-container form .table .td {
    /* margin: 1rem 0; */
    width: 25%;
    vertical-align: middle;
    border: 0.5px solid;
    border-bottom: 0.5px solid;
}


.project-list>tbody>tr>td {
    padding: 12px 8px;
}

/* .project-list>tbody>tr>td .avatar {
    width: 22px;
    border: 1px solid #CCC;
} */

.table-responsive {
    margin-top: 8vh;
    height: 500px;
}

.action-btn,
.cancel-btn,
.paid-btn {
    width: 100px;
    height: 25px;
    color: white;
    text-transform: capitalize;
}

.action-btn {
    background-color: #0da9ef;
    margin-right: 10px;
}

.cancel-btn,
.paid-btn {
    background-color: red;
}

.action-btn:hover {
    background-color: #27ae60;
}

 .invoice-container .invoice-form-container form .form-group {
    margin:0;
    font-size: 1.3rem;
    padding-top: 0%;
    padding-bottom: 0%;
} 

.invoice-container .invoice-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.invoice-container .invoice-form-container form .form-group1 {
    margin:0;
    font-size: 1.2rem;
} 
.invoice-container .invoice-form-container form .form-group2 {
    width:50%;
    font-size: 1.2rem;
} 
.invoice-container .btn {
    background: rgb(223, 80, 14);;
    color: rgb(240, 234, 231);
    border: 1rem;
    padding: 1rem;
    font: inherit;
    cursor: pointer;
    outline: 1rem;
    text-align: center;
   
}
.invoice-container .btnrow{
   align-content: center;
   padding-left: 50rem;
  
}
/* for first form */
.register-container {
    /* padding: 2rem 9%;
    padding-left: 35rem; */

       background-color: #fff;
    /* height: 100vh; */
    padding: 2rem 25%;
    font-size: 16px;
    align-content: center;
    
}

.register-container .register-form-container {
    background: #fff;
    /* width: 100% !important; */
}

.register-container .register-form-container form {
    /* position: relative; */
    /* left: 40%; */
    /* transform: translate(-50%, 0%); */
    /* max-width: 70rem; */

    /* width: 70%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear; */
        position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
    padding-left: 5rem;
   
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.3rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: bottom 1px solid; 
   
} 

.register-container .register-form-container form .form-control1 {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.3rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: bottom 1px solid; 
} 

.register-container .register-form-container form label {
    font-size: 1.2rem;
    margin: 0;
    padding: 0;
    float:left;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
    display: block; 
    overflow: hidden; 
    
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 10%;
    text-align: center;
    background-color : #af74a7;
    height: 30px;
    font-size: 1.3rem; 
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #e23838;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.invoice-container .invoice-form-container form .form-group2 .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
.register-container .register-form-container form .row .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>