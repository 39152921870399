<template>
    <div class="register-container">
        <!-- <router-link to="/vouchadd" class="btn" style="background-color:green ">Voucher</router-link> <br><br> -->
        <div class="register-form-container">
            <form id="SubjectSummaryForm" @submit="handleSubmit" novalidate autocomplete="off" >
    <div class="heading">
        <h1>
               Voucher Summery 
        </h1> 
    </div>
    <div class="form-group">
        <div class="row">
                <div class="col-sm-3">
                    <label for="iStamp">From Date
                    </label>
                    <input type="date" name="idate"  id="idate" class="form-control" 
                        v-model="subObj.idate" />
                </div>
                <div class="col-sm-3">
                    <label for="iStamp">To Date
                    </label>
                    <input type="date" name="todate"  id="todate" class="form-control" 
                        v-model="subObj.todate" />
                </div>
                <div class="col-sm-3">
                    <br>
                    <input type="button" @click="onChange()" value="Generate" class="btn" id ="gen" />
                </div>
                <br>
            </div>
        </div>
       <!-- go:{{ dailyreport }} -->
    <!-- <div class="form-group">
                   
                       <p v-for="ratehead in sabhaIncomeHead" :key="ratehead.emp_sb_rates "  
                           v-bind:value =ratehead.emp_sb_rates  >
                          
                           <router-link for="iHead" class="btn" to="/report"> {{ ratehead.emp_sb_rates  }} - {{ ratehead.h_description  }}
                           </router-link>
                        </p>
              
               </div>     -->
               <br>
        <div id="PrintContent">
            <div class="heading">
               <h4>Vouchers from {{ reportDate }} to {{toReportDate  }}
               </h4>
            </div>
               <div class="row">
               <table style="width: 98%; text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
               <thead>
                   <tr>   
                       <th>Index</th>
                       <th>Date</th>
                        <th>Voucher Num</th>  
                        <th>Amount</th>
                       <th>Expense Head</th>
                       <th>Description</th>
                       <th>Type</th>
                       <th>Income Head</th>
                       
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (r,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                       <td>{{ new Date(r.e_date).toLocaleDateString()}}</td>
                       <td>{{ r.voucher_num }}</td>
                       <td>{{ r.e_amount }}</td>
                       <td>{{ r.expense_head }}</td>
                       <td>{{ r.description }}</td>
                       <td v-if="r.cross_states==0"></td>
                       <td v-else>Cross</td>
                       <td v-if="r.income_head==0" ></td>
                       <td v-else>{{r.income_head}}</td>
                   </tr>
               </tbody>
           </table>
           </div>   
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>   
            </form>
            <div class="form-group">
                    <input type="button" value="Print" class="btn" @click="printContent"/>
                    
                </div>
            </div>
            </div>    
</template>
<script>
import axios from 'axios';
export default {
    name: 'VoucherDateRange',

    data() {
       return {
            nic:"",
            sabha: "",
            userLevel:"",
            subObj:{ idate:"",todate:""},
            reportDate:"",
            len:0,
            rangedata:[],
            toReportDate:"",
            itemsPerPage: 20,
            currentPage: 1,
       };
    },
created() {
       this.getDataFromSessionStorage()
     
   },
   computed:{
    paginatedData() {
        if (!Array.isArray(this.rangedata)) {
    return [];
  }
  
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
  return this.rangedata.slice(startIndex, endIndex);
},
  totalPages() {
    //   return Math.ceil(this.rangedata.length / this.itemsPerPage);
    return Array.isArray(this.rangedata) ? Math.ceil(this.rangedata.length / this.itemsPerPage) : 0;
    },
   },
   methods: {
    formattedDate() {
                let date = new Date()
                const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0');
          const day = String(date.getDate()).padStart(2, '0');
          return `${year}-${month}-${day}`;
              },

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel

                       }
            this.rangedata=await axios.get('/vourange/'+this.sabha+"/"+ this.subObj.idate+"/"+this.subObj.todate ).data
       },
       nextPage(event) {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
        prevPage(event) {
        if (this.currentPage > 1) {
            this.currentPage--;
        }
        // Prevent default form submission behavior
        event.preventDefault();
        },
       async onChange(){
       this.reportDate = this.subObj.idate
       this.toReportDate =this.subObj.todate
       let reportdata = await axios.get('/vourange/'+this.sabha+"/"+ this.subObj.idate+"/"+this.subObj.todate )
       this.rangedata = reportdata.data
       this.len =this.rangedata.length
    
       this.subObj.idate=""
       this.subObj.todate=""
    },
    printContent() {
        const prtHtml = document.getElementById('PrintContent').innerHTML;
        // Get all stylesheets HTML
        let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        // Open the print window
        const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            WinPrint.document.write(`


  
        ${stylesHtml}
  
  
         ${prtHtml}
  
    `);

        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
         }
    },
   

    }
</script>

<style scoped>
.btn {
    background-color: #e68a00;   
    width: 15rem;
    height: 2.7rem;
    font-size: small;
    padding-top: 0.3rem;
    /* padding-bottom: 0.5rem; */
}
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}

.ttable, th, td {
border: 1px solid;
}
.register-container {
    padding: 1.5rem 9%;
    min-height: 75vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    /* max-width: 98rem; */
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 0.5rem;
    font-size: 1.9rem;
    text-transform: uppercase;
    color: #130f40;
    /* margin: 0; */
    text-align: center;
    
}

.register-container .register-form-container form .form-control {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
}

.register-container .register-form-container form .form-control1 {
    margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
    font-size: 1.5rem;
    color: #130f40;
    text-transform: none;
    width: 40%;
    border: none;
    padding-bottom: 0.5rem;
}
.register-container .register-form-container form .form-radio {
    margin: 2rem 0%;
    border-radius: 0.5rem;
    background: #f7f7f7;
   padding-bottom: 1rem;
    font-size: 10rem;
    color: #130f40;

    width: 10%;
    border: none;
    margin-left: 10%;
}
.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 25px;
    /* padding-left: 5px;
    padding-right: 40px; */
    text-align: center;
    color: #022e2a;

}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 40%;
    text-align: center;
    align-items: center;
    /* background: #022e2a; */
    background-color : #032a2c;
    
}

.register-container .register-form-container form p {
    padding-top: 0.75rem;
    font-size: 1rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group  {
    margin: 0;
}

.register-container .register-form-container form .form-group  .error-mess {
    font-size: 1rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
    width: 100%;

} 
.register-container .register-form-container form .hide {
    display: none;

} 
</style>