<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="register-container">
        <div class="register-form-container">
            <form id="addBankAccForm" @submit="handleSubmit" novalidate autocomplete="off">
                <div class="heading">
                    <span>Add Bank Details</span>
                    <h4><b>බැංකු තොරතුරු</b></h4>
                </div>
                
                 
                <div class="form-group">
                    <label for="iCode">Account Number:
                    </label>
                    <input type="text" name="iAcc"  id="iAcc" class="form-control"
                        v-model="bankObj.accNum" />
                    <p class="error-mess" v-if="errorObj.accNumErr.length > 0">{{ errorObj.accNumErr[0] }}</p>
                </div>
                   
                <div class="form-group">
                    <label for="iCode">Bank:
                    </label>
                    <input type="text" name="iBank" id="iBank" class="form-control"
                        v-model="bankObj.bank" />
                    <p class="error-mess" v-if="errorObj.bankErr.length > 0">{{ errorObj.bankErr[0] }}</p>
                </div>
              

                <div class="form-group">
                    <input type="submit" value="Save" class="btn" />
                  
                </div>
 <!-- table -->
 <br>
 <div class="heading">
           <h3>Bank Details</h3>
         </div>
        <div class="row">
        
            <table class="ttable" >
                <thead>
                    <tr>
                        <th>Bank</th>
                        <th>Account Number</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" b in savedbanks" :key="b.emp_nic">

                        <td>{{ b.bank}}</td>
                        <td>{{  b.acc_no}}</td>
                        <!-- <td><input type="button" @click="deleteBankdetails(b.id)" value="Delete" class="btn"></td> -->
                        <td><router-link to="" @click="deleteBankdetails(b.id)"  class="fas fa-trash-alt"> </router-link></td>
                    </tr>
                </tbody>
            </table>
        </div>
            </form>
        </div>
    </div>

</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
//import { ref } from 'vue'
export default {

    name: "BankDetails",

    data() {
        return {
            bankObj: { accNum: "", bank: "", },
            errorObj: { accNumErr:[], bankErr: [] },
            matchHead: undefined,
            ratehead : '',
            nic:"",
            sabha: "",
            userLevel:"",
            savedbanks:[]
        }
    },

    created() {
        this.getDataFromSessionStorage()
       
      
    },
   
    methods: {
        async getDataFromSessionStorage(){
            const data = JSON.parse(sessionStorage.getItem('userData'))
            if(data){
                            this.nic = data.nic 
                            this.sabha = data.sabha
                            this.userLevel =data.userLevel
                        }
        this.savedbanks= (await axios.get('/sabhaaccounts/'+this.sabha)).data;
        },
       
      
        async matchAccNum(accNum) {
            let data = await axios.get('/bankaccounts/' + accNum );
            this.matchAcc = data.data;
        },
        async deleteBankdetails(ssid){
        let result = confirm("Confirm Delete?")
        if(result == true){

            await axios.delete("/deletaddacc/"+ssid );
            this.$refs.alert.showAlert('success', 'Bank Account Detail Deleted!')
            this.savedbanks= (await axios.get('/sabhaaccounts/'+this.sabha)).data;
        }
        
       },
        resetCheckErr: function () {
            this.errorObj.accNumErr = [];
            this.errorObj.bankErr = [];
          
        },
        checkEmptyErr: function () {
            for (var typeErr in this.errorObj) {
                if (this.errorObj[typeErr].length != 0) {
                    return false;
                }
            }
            return true;
        },
        checkForm: function () {
            this.resetCheckErr();

            // Head Id/code validate
            if (!this.bankObj.accNum) {
                this.errorObj.accNumErr.push("Account Number is required");
            }

            // Rate Head validate
            if (!this.bankObj.bank){
                this.errorObj.bankErr.push("Bank details field is required");
            }
             
        },
        
        async handleSubmit(e) {
            this.checkForm();


            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                e.preventDefault();
                await this.matchAccNum(this.bankObj.accNum);
                if (this.matchAcc) {
                    this.errorObj.bankErr.push("Account Number already exist")
                }
                else {
                    let data = {
                       
                        prs_code : this.sabha ,
                        acc_no: this.bankObj.accNum ,
                        bank: this .bankObj.bank,
                        
                    }
                    await axios.post("/savebank/", data)
                    this.$refs.alert.showAlert('success', 'Bank Details Added Successfully !')
                   
                    this.savedbanks= (await axios.get('/sabhaaccounts/'+this.sabha)).data
                    document.getElementById("addBankAccForm").reset()
                    this.bankObj.accNum ="",
                    this .bankObj.bank =""
                
                }
            }
        }

    },
    components: {
        VueBasicAlert
    }
}
</script>

<style scoped>

/* new */
.ttable {
     
     width: 100%;
     border-width:2px;
     border-color : #130f40;
     /* border-style: solid; */
     font-size: 1.2rem;
     /* background-color :white; */
     /* padding-left: 3rem; */
      /* height: 3rem; */
 }
 th  {
     text-align: center;
}
.ttable, th, td {
border: 1px solid;
text-align: center;
}
.register-container {
    padding: 2rem 9%;
    min-height: 72.3vh;
}

.register-container .register-form-container {
    background: #fff;

}

.register-container .register-form-container form {
    position: relative;
    left: 50%;
    transform: translate(-50%, 0%);
    max-width: 70rem;
    width: 100%;
    box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    padding: 2rem;
    border-radius: 0.5rem;
    animation: fadeUp 0.4s linear;
}

.register-container .register-form-container form h3 {
    padding-bottom: 1rem;
    font-size: 2rem;
    text-transform: uppercase;
    color: #130f40;
    margin: 0;
}

.register-container .register-form-container form .form-control {
     margin: 0.7rem 0;
    border-radius: 0.5rem;
    background: #f7f7f7;
    /* padding: 2rem 1.2rem; */
     font-size: 1.6rem; 
    color: #130f40;
    text-transform: none;
    width: 100%;
    border: margin-bottom; 
} 

.register-container .register-form-container form label {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.register-container .register-form-container form span {
    font-size: 18px;
    padding-left: 5px;
    padding-right: 40px;
     color: #022e2a;
}

.register-container .register-form-container form .btn {
    margin: 1rem 0;
    width: 100%;
    text-align: center;
    background-color:#022e2a ;

    
}

.register-container .register-form-container form p {
    padding-top: 1rem;
    font-size: 1.5rem;
    color: #666;
    margin: 0;
}

.register-container .register-form-container form p a {
    color: #27ae60;
}

.register-container .register-form-container form p a:hover {
    color: #130f40;
    text-decoration: underline;
}

.register-container .register-form-container form .form-group {
    margin: 0;
}

.register-container .register-form-container form .form-group .error-mess {
    font-size: 1.5rem;
    position: relative;
    color: rgb(243, 47, 47);
    margin: 0;
    padding: 0;
}
</style>