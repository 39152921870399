<template>
<div class="invoice-container" >
    <div class="invoice-form-container" >
        <vue-basic-alert :duration="400" :closeIn="2000" ref="alert" />   
        <form id="invoiceForm" novalidate autocomplete="off">
            <div id="Printcontent">
                <p style="text-align:right; font-size:smaller;" >Duplicate Copy | එල්.ජී.1</p>
                    <div class="form-group">
                       <p style="text-align: center; font-size: large;">Invoice | ලදුපත
                        <br>
                            {{sabhadetail.sb_name_en}} |  {{sabhadetail.sb_name_sin}}
                        </p>
                <table style="border: none; width: 100%;" >
                    <tr>
                        <td style="width: 50%; border: none;"></td>
                    <td style="border: none;">
                        <p for="iHead" style="text-align:left; font-size:small"><b>Receipt Number:</b> {{searchinvoice}}
                        <br>
                        <b>Date:</b> {{invoicedetails[0].date}}
                        </p> 
                    </td>
                </tr>
                   </table>
                <p><b>Customer name:</b> {{invoicedetails[0].cus_name}}<br>
                <b>Customer Address: </b>{{invoicedetails[0].cus_address}}</p>
    <div>(මුදල්- C චෙක්-Q ඝෘජු-D හරස්-X)</div>
            <table style="width: 100%;  border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">
            <thead>
                <tr >   
                    <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Description</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; ">Payment Type</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Amount</th>
                            <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Income Head</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for=" r in invoicedetails" :key="r.id">
                    
                    <td style="text-align: left; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; width: 40%;" >{{ r.description }}</td>
                    <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse; text-align: center;width: 10%;" >{{paytype}}</td>
                    <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{calVat(r.amount,r.vat) }}</td> -->
                    <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.discount }}</td> -->
                    <!-- <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.stamp }}</td> -->
                    <td style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.amount }}</td>
                    <td style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{ r.sb_rate_head }}</td>
                    <!-- <td>{{ r.sub_nic }}</td> -->
                  
                </tr>
                <tr>
                    <th style="text-align: center; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">Total:</th>
                   
                    <th colspan="2" style="text-align: right; border-width: 1px ; border-style: solid; border-color : #130f40; border-collapse: collapse;">{{calculateSummaryPrice()[0]}}</th>
                </tr>
            </tbody>
        </table>
        <p>Amount Received: {{calculateSummaryPrice()[5]}} Only<br>
        Cheque/Money Order Number : {{ cheqnum }} </p>
        </div>
        <table class="nobordertbl">
        <tr>
            <td style="text-align:left;width: 50%;">
               <!-- Prepaired By: -->

            </td>
            <td style="text-align:left;width: 50%;">
                Cashier: {{ userName }} 
            </td>
        </tr>
        <tr>
            <td style="text-align:left;width: 50%;">
                Date-Time:  {{ formattedDate(invoicedetails[0].date_time) }}
                <!-- Date-Time: {{ new Date().toLocaleString() }} -->

            </td>
            <td style="text-align:left;width: 50%;">
                Cashier Signature: .....................................
            </td>
        </tr>
    </table>
    <h6>For online payments use <span style="text-transform: lowercase;">https://elgservices.lk/</span></h6>
</div>
        </form>
        </div>
        <br>
        <div class="btnrowthis">
                <div class="">
                    <input type="button" value="Cancle Invoice" style="width: 150px;" class="btn" @click="PopupGenerate(searchinvoice)">&nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="button" value="Print" class=" btn" @click="printContent">
                </div>
            </div>
    </div>
         <!-- popup  -->
         <div class="modal" v-if="showPopup">
      <div class="modal-content" ref="modalRef">
        <button class="close-x" @click="closePopup"> X</button>
        <div class="modal-header">
          <h2>Cancel Invoice</h2>
        </div>
        <div class="modal-body">
                    <!-- Your content for 'Reject Request' goes here -->
                    <label for="reason">Reason for cancelling Invoice | ලදුපත අවලංගු කිරීමට හේතුව
                   </label>
                   <textarea name="reason" id="reason" class="form-control" v-model="objct.reason"></textarea>
        </div>

        <div class="modal-footer">

          <button class="close-button" @click="closePopup">Close</button>
          <button class="save-button" @click="cancelInvoice">Submit</button>
        </div>

      </div>
    </div>
    <!-- end popup -->
    </template>
    <script>
    import axios from 'axios';
    import VueBasicAlert from 'vue-basic-alert';
    export default {
        name: "InvoiceViewSecretary",
    
       data (){
    
        return{
                nic:"",
                sabha: "",
                userLevel:"",
                // invoicesavedetails:[],
                invoicedetails:[],
                searchinvoice:'',
                sabhadetail:[],
                matchUser:'',
                paytype:'',
                cheqnum:'',
                showPopup: false,
                objct:{reason:""},
                retrnnum:'',
        }
       },
    
       created(){
        this.getDataFromSessionStorage()
       
       },
       computed : {
                // isGreaterThan10th() {
                //     return this.currentDate.getDate() > 10;
                //     },
                
            },
    
       methods:{
        formattedDate(dateString) {
            // formatDate(dateString) {
    return new Date(dateString).toLocaleString('en-US', { timeZone: 'Asia/Colombo' });

              },
        async getDataFromSessionStorage(){
                const data = JSON.parse(sessionStorage.getItem('userData'))
                if(data){
                                this.nic = data.nic 
                                this.sabha = data.sabha
                                this.userLevel =data.userLevel
                                this.userName = data.userName
                            }
    
                this.searchinvoice = this.$route.params.data
                this.paytype=this.$route.params.pay_type
                this.cheqnum=this.$route.params.chq_num
                 this.sabhadetail= (await axios.get('/pra_sabha/' +this.sabha)).data
                 //get invoice save table data
                //  this.invoicesavedetails= (await axios.get('/pra_sabha/' +this.sabha)).data
                 this.invoicedetails = (await axios.get('/invoicesaved/' +this.sabha+'/'+this.searchinvoice)).data
                this.getMatchUser(this.nic)
                            
             
            },
            async getMatchUser(nic) {
                // await axios.get('/employee/' + nic);
                let data = await axios.get('/employee/' + nic);
                this.matchUser = data.data;
            },
            calculateSummaryPrice: function () {
            let subtotal = 0;
            let discount = 0;
            let stampfee =0;
            let vat =0;
            let i = 0;
            let subamount=0;
            // let disc =0;
            // let stamp=0;
            // let vattemp =0;
            let vatamount =0;
            let amount=0;
            while (i < this.invoicedetails.length) {
                // subtotal = subtotal +(parseInt(this.invoicedetails[i].amount)- (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100) +parseInt(this.invoicedetails[i].stamp))
                // subtotal = subtotal +(parseInt(this.invoicedetails[i].amount)- (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100) +(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].stamp) )/100))+(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].vat) )/100)
                discount = discount + (parseFloat(this.invoicedetails[i].discount))
                stampfee = stampfee + (parseFloat(this.invoicedetails[i].stamp))
                amount = parseFloat(this.invoicedetails[i].amount)
                vatamount=amount* parseFloat((this.invoicedetails[i].vat) /100)
                vat = vat+ vatamount
                subtotal = subtotal + (parseFloat(this.invoicedetails[i].amount))- (parseFloat(this.invoicedetails[i].discount)) + (parseFloat(this.invoicedetails[i].stamp)) +(parseFloat(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].vat) )/100);
                // subtotal=subtotal+stampfee+vat+parseFloat(this.invoicedetails[i].amount)-discount
                // discount =(parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].discount) )/100)
                // stamp = (parseInt(this.invoicedetails[i].amount) * parseInt((this.invoicedetails[i].stamp) )/100)
                subamount =subamount+parseFloat(this.invoicedetails[i].amount)
                i = i + 1
                
            }
            
            let total = subtotal ;
            let fixedtotwo=total
            this.numberToText(fixedtotwo) 
           
            return [total.toFixed(2), discount.toFixed(2),stampfee.toFixed(2),vat.toFixed(2),subamount.toFixed(2),this.retrnnum];
        },
       calVat:function name(amo,vt) {
        let vatamount= parseFloat(amo)*parseFloat(vt)/100

        return vatamount.toFixed(2)
       },
       numberToText (convnumber) {
        // convnumber=this.calculateSummaryPrice()[0]
  // Basic implementation, you can expand this to cover more cases
  const ones = ['', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine'];
      const teens = ['ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'];
      const tens = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];
      const suffixes = ['', 'thousand','million', 'billion', 'trillion']; // Adjust as needed

      const [wholePart, decimalPart] = convnumber.toString().split('.').map(Number);

      let wholePartText = this.convertWholeNumberToText(wholePart, ones, teens, tens,suffixes);
      let decimalPartText = this.convertWholeNumberToText(decimalPart, ones, teens, tens,suffixes);

      let result = wholePartText +' Rupees ';
      if (decimalPartText !== '') {
        if(decimalPart.toString().length>1){
            console.log(decimalPart.length)
        result += ' and ' + decimalPartText + " Cents ";
        }else{
            let decimalpartone = decimalPart*10
            let decimalPartTextcents = this.convertWholeNumberToText(decimalpartone, ones, teens, tens,suffixes);
            result += ' and ' + decimalPartTextcents + " Cents ";
        }
      }

    //   return result.trim();
    this.retrnnum= result.trim();
    },
    convertWholeNumberToText(number, ones, teens, tens, suffixes) {
    if (number === 0) {
        return 'zero';
    }

    let result = '';
    let suffixIndex = 0;

    while (number > 0) {
        if (number % 1000 !== 0) {
            result = this.convertThreeDigitNumberToText(number % 1000, ones, teens, tens) + ' ' + suffixes[suffixIndex] + ' ' + result;
        }
        number = Math.floor(number / 1000);
        suffixIndex++;
    }

    return result.trim();
},
    convertThreeDigitNumberToText(number, ones, teens, tens) {
      if (number === 0) {
        return 'zero';
      }

      let result = '';

         if (number >= 100) {
        result += ones[Math.floor(number / 100)] + ' hundred ';
        number %= 100;
      }

      if (number >= 20) {
        result += tens[Math.floor(number / 10)] + ' ';
        number %= 10;
      }

      if (number >= 10) {
        result += teens[number - 10] + ' ';
        return result.trim();
      }

      if (number > 0) {
        result += ones[number] + ' ';
      }

  return result.trim();
 
},
       singleRowTotal:function(vat,amount,stam,disc){
        let total = (parseFloat(amount)+ parseFloat(vat)+ parseFloat(stam))-parseFloat(disc);
        return total.toFixed(2)
       }, 
            PopupGenerate(id){
            this.searchinvoice=id
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
            },

           async cancelInvoice(){
            let canceldata={
                invoice_status:"1"
            }
            await axios.put("/canclinvoi/"+this.searchinvoice,canceldata)
            let canseltempsave ={
                invoice_status:"1"
            }
            await axios.put("/canceltempsaveinvoice/"+this.searchinvoice,canseltempsave)
            
            const data={
                sabha : this.sabha,
                invoice_num : this.searchinvoice ,
                cancelled_nic: this.nic,
                cancelled_reason: this.objct.reason
            }
            await axios.post("/canclewthreason/", data)
            this.$refs.alert.showAlert('success', 'Invoice Cancled!')
            this.closePopup();

           },
           openPopup() {
            this.showPopup = true;
            document.addEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closePopup() {
            this.showPopup = false;
            document.removeEventListener('mouseup', this.closeModalOnClickOutside);
        },
        closeModalOnClickOutside(event) {
            const modal = this.$refs.modalRef;
            if (!modal.contains(event.target)) {
                this.closePopup();
            }
        },
        printContent() {

const prtHtml = document.getElementById('Printcontent').innerHTML;
    const WinPrint = window.open('', '', '');
    WinPrint.document.write(`    
${prtHtml}
`);
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
}
        },
    
        components: {
           VueBasicAlert
       }
            
    
    };
    </script>
    <style scoped>
    .nobordertbl{
            border-collapse: collapse;
            border: none;
            font-size: 1.2rem;
            width:100%;
        }
        .nobordertbl td{
            border: none;
        }
    .ttable {
         
         width: 100%;
         border-width:1px !important;
         border-color : #130f40;
         /* border-style: solid; */
         font-size: 0.8rem;
        text-align: right;
         /* background-color :white; */
         /* padding-left: 3rem; */
          /* height: 3rem; */
          border-collapse: collapse;
          
     }
     th  {
         text-align: center;
    }
    .ttable, th, td {
    border: 1px solid;
    /* padding-right: 0.7rem; */
    }
    .invoice-container {
        background-color: #fff;
        /* height: 100vh; */
        padding: 2rem;
        font-size: 16px;
        align-content: center;
        min-height: 72.3vh;
    }
    .invoice-container .invoice-form-container {
        background: #fff;
    
    }
    
    .invoice-container .invoice-form-container .heading{
       padding: 0%;
    
    }
    
    .invoice-container .invoice-form-container form {
        position: relative;
        left: 50%;
        transform: translate(-50%, 0%);
        max-width: 70rem;
        width: 100%;
        box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
        border: 0.1rem solid rgba(0, 0, 0, 0.2);
        padding: 2rem;
        border-radius: 0.5rem;
        animation: fadeUp 0.4s linear;
        padding-left: 5rem;
    }
    .h4 {
        /* padding-bottom: 1rem; */
        font-size: 1.5rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .invoice-container .invoice-form-container form h3 {
        /* padding-bottom: 1rem; */
        font-size: 1.3rem;
        text-transform: uppercase;
        color: #130f40;
        margin: 0;
    }
    .invoice-container .invoice-form-container form .table {
        /* margin: 1rem 0; */
        width: 95%;
        /* border-style: solid; */
        font-size: 1.2rem;
        /* background-color : #e9e4e9; */
        padding-left: 5rem;
         /* height: 3rem; */
         border-color : #130f40;
         /* border-width:2px; */
         border-top: 0.5px solid;
         border-left: 0.5px solid;
         border-right: 0.5px solid;
         border-bottom: 0.5px solid;
    }
    .invoice-container .invoice-form-container form .table .td {
        /* margin: 1rem 0; */
        width: 25%;
        vertical-align: middle;
        border: 0.5px solid;
        border-bottom: 0.5px solid;
    }
    .invoice-container .invoice-form-container form .form-group {
        margin:0;
        font-size: 1.3rem;
        padding-top: 0%;
        padding-bottom: 0%;
    } 
    .invoice-container .invoice-form-container form .form-group1 {
        margin:0;
        font-size: 1.2rem;
    } 
    .btnrowthis{
        padding-left: 40.0rem;
    }
    .modal {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999;
  }

  .modal-content {
      background-color: white;
      /* padding: 5px; */
      border-radius: 1px;
      /* max-width: 500px; */
      width: 40%;
      position: relative;
  }

  .close x {
      position: absolute;
      top: 5px;
      right: 10px;
      padding: 5px;
      background-color: transparent;
      border: 1rem;
      cursor: pointer;
      font-size: 18px;
      color: #555;
  }

  .close-x:hover {
      color: #ff0000;
      /* Change to your desired hover color */
  }


  .modal-header {
      /* padding-bottom: 2px; */
      border-bottom: 1px solid #ccc;
  }

  .modal-body {
      /* padding: 10px 20px 20px 20px; */
      padding-left: 5%; 
      font-size: 14px;

  }
  .modal-footer {
      padding-top: 5px;
      border-top: 1px solid #ccc;
      display: flex;
      justify-content: flex-end;
  }
  .close-button,
  .save-button {
      padding: 5px 10px;
      border: none;
      cursor: pointer;
      font-weight: bold;
      border-radius: 4px;
      transition: background-color 0.3s ease;
  }

  .close-button {
      background-color: #f44336;
      color: white;
  }

  .save-button {
      background-color: #4CAF50;
      color: white;
      margin-left: 5px;
  }

  .close-button:hover {
      background-color: #d32f2f;
  }

  .save-button:hover {
      background-color: #45a049;
  }
  .rowpop{
    padding-left: 30%;
  }
    </style>